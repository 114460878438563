import { pluralSets, pluralText } from '../../../methods/text.methods';
import { ActionType, Player, TextResult } from '../../../types/types';
import { TextBlock } from '../../texts/TextBlock';

interface Props {
  players: Player[];
  results: TextResult[];
  myStatus: ActionType;
}

type Cells = {
  [key in ActionType]: {
    color: string;
    statusText: string;
  };
};

const cells: Cells = {
  [ActionType.Greetings]: {
    color: 'gray',
    statusText: 'Добавился',
  },
  [ActionType.AwaitName]: {
    color: 'gray',
    statusText: 'Выбирает имя',
  },
  [ActionType.AwaitText]: {
    color: 'yellow',
    statusText: 'пишет текст',
  },
  [ActionType.AwaitStartVoting]: {
    color: 'green',
    statusText: 'ждёт тексты',
  },
  [ActionType.AwaitVoting]: {
    color: 'yellow',
    statusText: 'читает и голосует',
  },
  [ActionType.AwaitEndVoting]: {
    color: 'green',
    statusText: 'ждёт голосующих',
  },
  [ActionType.GameEnded]: {
    color: 'green',
    statusText: 'закончил игру',
  },
  [ActionType.Banned]: {
    color: 'black',
    statusText: 'Выбыл',
  },
};

const PlayerComponent = ({ player, index }: { player: Player; index: number }) => {
  const { color, statusText } = cells[player.status];
  return (
    <div className={`player ${color}`}>
      <div className="index">#{index}</div>
      <div className="status">{statusText}</div>
    </div>
  );
};

export const Game = (props: Props) => {
  if (props.myStatus != ActionType.AwaitEndVoting && props.myStatus != ActionType.GameEnded) {
    return (
      <div className="game">
        <div className="blocked">Статусы участников станут видны, когда ты проголосуешь</div>
      </div>
    );
  }

  if (props.myStatus == ActionType.AwaitEndVoting) {
    return (
      <div className="game">
        <div className="players">
          {props.players.map((player, index) => {
            return <PlayerComponent player={player} index={index + 1} key={index} />;
          })}
        </div>
      </div>
    );
  }

  const textsSorted = [...props.results];
  textsSorted.sort(function (a, b) {
    if (a.rating < b.rating) return 1;
    if (a.rating > b.rating) return -1;
    return 0;
  });

  if (props.myStatus == ActionType.GameEnded) {
    return (
      <div className="game">
        <div className="texts">
          {textsSorted.map((text, index) => {
            return (
              <div className="textBlock">
                <div className="rating">
                  <span>
                    {index + 1} место, {pluralText(text.rating, pluralSets.balls)}
                  </span>
                </div>
                <div className="texttitle">
                  <span>«{text.title}»</span>
                </div>
                <div>
                  <a href={text.link} target="_blank" rel="noopener noreferrer">
                    Ссылка на текст
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};
