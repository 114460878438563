import { ResultsState } from '../../../store/results.slice';
import { ActionType, ExtraVoteByType } from '../../../types/types';
import { pluralSets, pluralText } from './../../../methods/text.methods';
import { ExtraVoteBlock } from './TextsAdmin';

interface Props {
  results: ResultsState;
  myStatus: ActionType;
}

export const MyResults = (props: Props) => {
  if (props.myStatus != ActionType.GameEnded) {
    return (
      <div className="game">
        <div className="blocked">Результаты появятся, когда закончится игра</div>
      </div>
    );
  }

  const { results } = props;

  return (
    <div className="results">
      <div>рассказ</div>
      <h2>«{results.title}»</h2>
      <div>набрал</div>
      <h2>{pluralText(results.rating, pluralSets.balls)}</h2>
      <div>и занял</div>
      <h2>{results.myPlace} место</h2>
      <div className="separator" />
      <div>За него проголосовало {pluralText(results.votesTotal, pluralSets.people)}</div>
      <div className="separator" />
      <div>и он получил {pluralText(results.extraVotesTotal, pluralSets.extraVotesGen)}</div>
      <div className="statsGrid4">
        <ExtraVoteBlock type="like" extraVotes={results.extraVotes} />
        <ExtraVoteBlock type="theme" extraVotes={results.extraVotes} />
        <ExtraVoteBlock type="readable" extraVotes={results.extraVotes} />
        <ExtraVoteBlock type="style" extraVotes={results.extraVotes} />
      </div>
      <div className="separator" />
      <div>его длина — {pluralText(results.characters, pluralSets.characters)} </div>
      <div className="separator" />
      <div>он длиннее {pluralText(results.textsShorterMine, pluralSets.textsGen)} </div>
      <div className="separator" />
      <div>и короче {pluralText(results.textsLongerMine, pluralSets.textsGen)} </div>
    </div>
  );
};
