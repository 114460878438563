import { ProfileState } from '../../store/profile.slice';
import { TextBlock } from './TextBlock';
import { Text } from '../../types/types';

interface Props {
  texts: Text[];
  profile: ProfileState;
}

export const Texts = (props: Props) => {
  return (
    <>
      <div className="texts">
        {props.texts.map((text, index) => {
          const badges = props.profile.extraVotes.filter((vote) => vote.textIndex == index + 1);
          return <TextBlock text={text} profile={props.profile} index={index + 1} key={index} badges={badges} canExtraVoting />;
        })}
      </div>
    </>
  );
};
