import React from 'react';
import { ExtraVote, ExtraVoteValue, ProfileState } from '../../../store/profile.slice';
import { TextBlock } from '../../texts/TextBlock';
import { ActionType, Text } from '../../../types/types';

interface Props {
  texts: Text[];
  profile: ProfileState;
}

type StatusDecoded = {
  [key in ActionType]: string;
};

export const statusDecoder: StatusDecoded = {
  [ActionType.Greetings]: 'Только пришёл к нам',
  [ActionType.AwaitName]: 'Ожидается ввод имени',
  [ActionType.AwaitText]: 'Ожидается отправка текста',
  [ActionType.AwaitStartVoting]: 'Ждёт, пока все сдадут тексты',
  [ActionType.AwaitVoting]: 'Читает тексты и выставляет оценки',
  [ActionType.AwaitEndVoting]: 'Ждёт, пока все выставят оценки',
  [ActionType.GameEnded]: 'Закончил игру и получил результаты',
  [ActionType.Banned]: 'Забанен 💀',
};

const stages = [
  {
    title: 'Зарегистрировался',
    need: [
      ActionType.AwaitName,
      ActionType.AwaitText,
      ActionType.AwaitStartVoting,
      ActionType.AwaitVoting,
      ActionType.AwaitEndVoting,
      ActionType.GameEnded,
      ActionType.Banned,
    ],
  },
  {
    title: 'Выбрал имя',
    need: [ActionType.AwaitText, ActionType.AwaitStartVoting, ActionType.AwaitVoting, ActionType.AwaitEndVoting, ActionType.GameEnded],
  },
  {
    title: 'Отправил текст',
    need: [ActionType.AwaitStartVoting, ActionType.AwaitVoting, ActionType.AwaitEndVoting, ActionType.GameEnded],
  },
  {
    title: 'Начал читать и голосовать',
    need: [ActionType.AwaitVoting, ActionType.AwaitEndVoting, ActionType.GameEnded],
  },
  {
    title: 'Проголосовал',
    need: [ActionType.AwaitEndVoting, ActionType.GameEnded],
  },
  {
    title: 'Закончил игру',
    need: [ActionType.GameEnded],
  },
];

export const Profile = (props: Props) => {
  const badgesCount = {
    like: props.profile.extraVotes.filter((vote) => vote.type == 'like').length,
    theme: props.profile.extraVotes.filter((vote) => vote.type == 'theme').length,
    readable: props.profile.extraVotes.filter((vote) => vote.type == 'readable').length,
    style: props.profile.extraVotes.filter((vote) => vote.type == 'style').length,
  };

  return (
    <>
      <div className="bio">
        <div className="bioLine">
          <strong>Имя:</strong> {props.profile.name}
        </div>
        <div className="bioLine">
          <strong>Статус:</strong> {statusDecoder[props.profile.status]}
        </div>
        <div className="stages">
          {stages.map((stage, index) => {
            if (stage.need.includes(props.profile.status)) {
              return (
                <div key={index} className="stage">
                  ✅ {stage.title}
                </div>
              );
            } else {
              return (
                <div key={index} className="stage">
                  ⏳ {stage.title}
                </div>
              );
            }
          })}
        </div>
        <hr />
        <h3>Выбранные тексты</h3>
        <div className="texts">
          {props.profile.votes.length > 0 &&
            props.profile.votes.map((vote, index) => {
              const text = props.texts.find((text) => text.link == vote.link);
              const textNumber = props.texts.findIndex((text) => text.link == vote.link);
              const badge: ExtraVote = {
                type: 'choose',
                value: vote.value as ExtraVoteValue,
                textIndex: 1,
              };
              if (text && textNumber > -1) {
                return <TextBlock text={text} profile={props.profile} index={textNumber + 1} key={index} badges={[badge]} />;
              }
            })}
          {props.profile.votes.length == 0 && <div>Ещё не выбраны</div>}
        </div>

        <hr />
        <h3>Дополнительные оценки:</h3>
        <div>
          <strong>Общее впечатление:</strong> {badgesCount.like} из {props.texts.length - 1}
        </div>
        <div>
          <strong>Соответствие теме:</strong> {badgesCount.theme} из {props.texts.length - 1}
        </div>
        <div>
          <strong>Читабельность:</strong> {badgesCount.readable} из {props.texts.length - 1}
        </div>
        <div>
          <strong>Красота текста:</strong> {badgesCount.style} из {props.texts.length - 1}
        </div>
      </div>
    </>
  );
};
