import cn from 'classnames';
import { TextAdmin } from '../../../types/types';
import { ExtraVoteType } from '../../../store/profile.slice';
import { pluralSets, pluralText } from '../../../methods/text.methods';
import { statusDecoder } from './Profile';

interface Props {
  textsAdmin: TextAdmin[];
}

interface ExtraVoteBlockProps {
  extraVotes: TextAdmin['extraVotes'];
  type: ExtraVoteType;
}

export const ExtraVoteBlock = (props: ExtraVoteBlockProps) => {
  const { extraVotes, type } = props;
  const extraVotesTotal = extraVotes[type].good + extraVotes[type].neutral + extraVotes[type].bad;

  const goodPercent = Math.round((extraVotes[type].good / extraVotesTotal) * 100);
  const neutralPercent = Math.round((extraVotes[type].neutral / extraVotesTotal) * 100);
  const badPercent = Math.round((extraVotes[type].bad / extraVotesTotal) * 100);

  interface TypeDescription {
    [key: string]: string;
  }

  const typeDescription: TypeDescription = {
    like: 'Общее впечатление',
    theme: 'Соответствие теме',
    readable: 'Читабельность',
    style: 'Красота текста',
  };

  if (extraVotesTotal == 0) {
    return (
      <div className="statsCell">
        <div className="statTitle">{typeDescription[type]}</div>
        <div className="statValue">нет данных</div>
      </div>
    );
  }

  return (
    <div className="statsCell">
      <div className="statTitle">{typeDescription[type]}</div>
      <div className="statValue">
        <div className="statsGrid3">
          <div className="green">{extraVotes[type].good}</div>
          <div className="yellow">{extraVotes[type].neutral}</div>
          <div className="red">{extraVotes[type].bad}</div>
        </div>
        <div className="statsGrid3">
          <div className="green">{goodPercent}%</div>
          <div className="yellow">{neutralPercent}%</div>
          <div className="red">{badPercent}%</div>
        </div>
      </div>
    </div>
  );
};

export const TextsAdmin = (props: Props) => {
  const textSortedByRating = [...props.textsAdmin];

  function compare(a: TextAdmin, b: TextAdmin) {
    if (a.rating > b.rating) {
      return -1;
    }
    if (a.rating < b.rating) {
      return 1;
    }
    return 0;
  }

  textSortedByRating.sort(compare);

  function getMaxMinBytype(type: ExtraVoteType, textsAdmin: TextAdmin[]) {
    const goodValuesArray = props.textsAdmin.map((text) => text.extraVotes[type].good);
    const bestValueIndex = goodValuesArray.indexOf(Math.max(...goodValuesArray));

    const neutralValuesArray = props.textsAdmin.map((text) => text.extraVotes[type].neutral);
    const middleValueIndex = neutralValuesArray.indexOf(Math.max(...neutralValuesArray));

    const badValuesArray = props.textsAdmin.map((text) => text.extraVotes[type].bad);
    const worseValueIndex = badValuesArray.indexOf(Math.max(...badValuesArray));

    return { best: textsAdmin[bestValueIndex], middle: textsAdmin[middleValueIndex], worse: textsAdmin[worseValueIndex] };
  }

  const like_minMax = getMaxMinBytype('like', props.textsAdmin);
  const theme_minMax = getMaxMinBytype('theme', props.textsAdmin);
  const readable_minMax = getMaxMinBytype('readable', props.textsAdmin);
  const style_minMax = getMaxMinBytype('style', props.textsAdmin);

  return (
    <div className="textsAdmin">
      {props.textsAdmin.map((text, index) => {
        const ratingNumber = textSortedByRating.findIndex((text2) => text2.user_id == text.user_id) + 1;

        return (
          <div
            className={cn('textBlock', {
              gold: ratingNumber == 1,
              silver: ratingNumber == 2,
              bronze: ratingNumber == 3,
            })}
            key={index}
          >
            <div className="texttitle">
              <span>
                #{index + 1}: «{text.title}»
              </span>
            </div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                Ссылка на текст
              </a>
            </div>
            <div className="textStats">
              <div>
                Автор: {text.user} ({text.user_id})
              </div>
              <div>Реальный автор: {text.realUser}</div>
              <div>Статус: {statusDecoder[text.status]}</div>
              <div className="statsGrid2">
                <div className="statsCell">
                  <div className="statTitle">Голосов</div>
                  <div className="statValue">{text.rating}</div>
                </div>
                <div className="statsCell">
                  <div className="statTitle">Место</div>
                  <div className="statValue">#{ratingNumber}</div>
                </div>
              </div>
              <div className="statsGrid4">
                <ExtraVoteBlock type="like" extraVotes={text.extraVotes} />
                <ExtraVoteBlock type="theme" extraVotes={text.extraVotes} />
                <ExtraVoteBlock type="readable" extraVotes={text.extraVotes} />
                <ExtraVoteBlock type="style" extraVotes={text.extraVotes} />
              </div>
            </div>
          </div>
        );
      })}

      <hr />

      <div className="tops">
        <h3>Общее впечатление:</h3>
        <div className="green">
          Лучшее — «{like_minMax.best.title}» ({like_minMax.best.user} aka {like_minMax.best.realUser}) —{' '}
          {pluralText(like_minMax.best.extraVotes.like.good, pluralSets.balls)}
        </div>
        <div className="yellow">
          Среднее — «{like_minMax.middle.title}» ({like_minMax.middle.user} aka {like_minMax.middle.realUser}) —{' '}
          {pluralText(like_minMax.middle.extraVotes.like.neutral, pluralSets.balls)}
        </div>
        <div className="red">
          Худшее — «{like_minMax.worse.title}» ({like_minMax.worse.user} aka {like_minMax.worse.realUser}) —{' '}
          {pluralText(like_minMax.worse.extraVotes.like.bad, pluralSets.balls)}
        </div>

        <h3>Соответствие теме:</h3>
        <div className="green">
          Лучшее — «{theme_minMax.best.title}» ({theme_minMax.best.user} aka {theme_minMax.best.realUser}) —{' '}
          {pluralText(theme_minMax.best.extraVotes.theme.good, pluralSets.balls)}
        </div>
        <div className="yellow">
          Среднее — «{theme_minMax.middle.title}» ({theme_minMax.middle.user} aka {theme_minMax.middle.realUser}) —{' '}
          {pluralText(theme_minMax.middle.extraVotes.theme.neutral, pluralSets.balls)}
        </div>
        <div className="red">
          Худшее — «{theme_minMax.worse.title}» ({theme_minMax.worse.user} aka {theme_minMax.worse.realUser}) —{' '}
          {pluralText(theme_minMax.worse.extraVotes.theme.bad, pluralSets.balls)}
        </div>

        <h3>Читабельность:</h3>
        <div className="green">
          Лучшее — «{readable_minMax.best.title}» ({readable_minMax.best.user} aka {readable_minMax.best.realUser}) —{' '}
          {pluralText(readable_minMax.best.extraVotes.readable.good, pluralSets.balls)}
        </div>
        <div className="yellow">
          Среднее — «{readable_minMax.middle.title}» ({readable_minMax.middle.user} aka {readable_minMax.middle.realUser}) —{' '}
          {pluralText(readable_minMax.middle.extraVotes.readable.neutral, pluralSets.balls)}
        </div>
        <div className="red">
          Худшее — «{readable_minMax.worse.title}» ({readable_minMax.worse.user} aka {readable_minMax.worse.realUser}) —{' '}
          {pluralText(readable_minMax.worse.extraVotes.readable.bad, pluralSets.balls)}
        </div>

        <h3>Красота текста:</h3>
        <div className="green">
          Лучшее — «{style_minMax.best.title}» ({style_minMax.best.user} aka {style_minMax.best.realUser}) —{' '}
          {pluralText(style_minMax.best.extraVotes.style.good, pluralSets.balls)}
        </div>
        <div className="yellow">
          Среднее — «{style_minMax.middle.title}» ({style_minMax.middle.user} aka {style_minMax.middle.realUser}) —{' '}
          {pluralText(style_minMax.middle.extraVotes.style.neutral, pluralSets.balls)}
        </div>
        <div className="red">
          Худшее — «{style_minMax.worse.title}» ({style_minMax.worse.user} aka {style_minMax.worse.realUser}) —{' '}
          {pluralText(style_minMax.worse.extraVotes.style.bad, pluralSets.balls)}
        </div>
      </div>
    </div>
  );
};
