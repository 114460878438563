import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../types/types';

export type Rate = 'good' | 'bad' | 'neutral';

export type ExtraVoteValue = 1 | 2 | 3;
export type ExtraVoteType = 'theme' | 'like' | 'readable' | 'style' | 'choose';

export interface ExtraVote {
  type: ExtraVoteType;
  value: ExtraVoteValue;
  textIndex: number;
}

export interface ProfileState {
  name: string;
  status: ActionType;
  votes: {
    link: string;
    value: number;
  }[];
  extraVotes: ExtraVote[];
}

const initialState: ProfileState = {
  name: '',
  status: ActionType.Greetings,
  votes: [],
  extraVotes: [],
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileState>) => {
      state.name = action.payload.name;
      state.status = action.payload.status;
      state.votes = action.payload.votes;
      state.extraVotes = action.payload.extraVotes;
    },
    addExtraVote: (state, action: PayloadAction<ExtraVote>) => {
      state.extraVotes = [...state.extraVotes, action.payload];
    },
  },
});
