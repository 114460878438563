export type PluralSet = [string, string, string];

type PluralSetCollection = {
  // [x: string]: PluralSet;
  balls: PluralSet;
  votes: PluralSet;
  people: PluralSet;
  extraVotesGen: PluralSet;
  textsGen: PluralSet;
  characters: PluralSet;
};

export const pluralSets: PluralSetCollection = {
  balls: ['балл', 'балла', 'баллов'],
  votes: ['голос', 'голоса', 'голосов'],
  people: ['человек', 'человека', 'человек'],
  extraVotesGen: ['дополнительную оценку', 'дополнительные оценки', 'дополнительных оценок'],
  textsGen: ['текста', 'текстов', 'текстов'],
  characters: ['знак', 'знака', 'знаков'],
};

export const pluralText = (number: number, texts: PluralSet, textOnly?: boolean) => {
  let cases = [2, 0, 1, 1, 1, 2];

  if (textOnly) {
    return texts[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  }

  return number + ' ' + texts[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};
