import React from 'react';

interface Props {}

export const Rules = (props: Props) => {
  return (
    <>
      <div className="rules">
        <div className="line">Мы собираемся все вместе и пишем небольшие рассказы по своему сеттингу по заранее выбранной теме.</div>
        <div className="line">
          Важный момент - наличие телеграма, так как тексты нужно сдавать при помощи бота для обеспечения анонимности и проведения голосования. В самом боте
          есть все инструкции.{' '}
          <a href="https://t.me/gralka_bot" target="_blank">
            https://t.me/gralka_bot
          </a>
        </div>

        <h3>Расписание</h3>

        <table>
          <thead>
            <tr>
              <td>Когда</td>
              <td>Сколько</td>
              <td>Что делаем</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>21 - 22 сентября</td>
              <td>2 дня</td>
              <td>Выбор темы</td>
            </tr>
            <tr>
              <td>23 сентября - 2 октября</td>
              <td>10 дней</td>
              <td>Написание текстов</td>
            </tr>
            <tr>
              <td>3 октября - 6 октября</td>
              <td>4 дня</td>
              <td>Чтение и голосование</td>
            </tr>
            <tr>
              <td>7 октября</td>
              <td></td>
              <td>Результаты</td>
            </tr>
          </tbody>
        </table>

        <h3>Правила</h3>

        <div className="line">
          ★ Тексты сдаются через бота в телеграме общедоступной ссылкой на гугл-документы. Ссылки на яндекс-диск и другие сервисы не принимаются. Все ссылки
          будут проверены, и если они не работают, вам об этом скажут
        </div>

        <div className="line">
          ★ В качестве имени в боте можно использовать реальное имя или выдуманное, если вы хотите сохранить интригу после объявления результатов
        </div>

        <div className="line">★ Текст должен быть написан специально для движа по любому вашему сеттингу, ранее написанные тексты не принимаются</div>

        <div className="line">★ Измените имена и названия в тексте для сохранения анонимности</div>

        <div className="line">★ Желательно, чтобы у текста было любое, хотя бы самое простое название, не совпадающее с темой</div>

        <div className="line">★ Объем текста от 1000 до 10 000 знаков (с учётом пробелов)</div>

        <div className="line">★ В тексте не должно быть материалов 18+ и материалов, нарушающих действующее законодательство</div>

        <div className="line">
          ★ Не запрещены оригинальные шрифты, оформление текста, в том числе иллюстрациями, но оценка должна выставляться только за содержание текста
        </div>

        <div className="line">
          ★ На этапе голосования вы должны указать 5 номеров понравившихся текстов. Нужно помнить, что их порядок имеет значение. Первый текст получает больше
          баллов, чем второй и третий
        </div>

        <div className="line">★ До окончания движа нужно сохранять анонимность</div>

        <div className="line">★ Пропустившие сдачу работ будут исключены из движа и не получат свои результаты</div>

        <div className="line">★ Пропустившие голосование будут исключены из движа и не получат свои результаты</div>

        <div className="line">★ После объявления результатов, если авторы согласны на получение рецензий, вы можете написать им рецензию</div>

        <div className="line">★ Дополнительные оценки вашего текста могут быть получены, если автор согласен на это</div>
      </div>
    </>
  );
};
