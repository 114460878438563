import { AxiosResponse } from 'axios';
import { axiosInstance } from './adapter';
import { ExtraVoteType, ExtraVoteValue, ProfileState } from '../store/profile.slice';
import { Text, TextAdmin, UserAdmin } from '../types/types';
import { GameState } from '../store/game.slice';
import { ResultsState } from '../store/results.slice';

export function getProfileByGuidApi(guid: string): Promise<AxiosResponse<ProfileState, any>> {
  return axiosInstance.get(`/profile/${guid}`);
}

export function getTextsByGuidApi(guid: string): Promise<AxiosResponse<Text[], any>> {
  return axiosInstance.get(`/texts/${guid}`);
}

export function getPlayersByGuidApi(guid: string): Promise<AxiosResponse<GameState, any>> {
  return axiosInstance.get(`/players/${guid}`);
}

export function getResultsByGuidApi(guid: string): Promise<AxiosResponse<ResultsState, any>> {
  return axiosInstance.get(`/my_results/${guid}`);
}

export interface ExtraVoteBody {
  guid: string;
  textIndex: number;
  type: ExtraVoteType;
  value: ExtraVoteValue;
}

export interface ExtraVoteClearBody {
  guid: string;
  textIndex: number;
}

export function postExtraVote(extraVoteBody: ExtraVoteBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/extra_vote/`, extraVoteBody);
}

export function postExtraVoteClear(extraVoteClearBody: ExtraVoteClearBody): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/extra_vote_clear/`, extraVoteClearBody);
}

export type VoteAdmin = {
  rating: number;
  created_at: string;
  fromUser: {
    id: string;
    name: string;
  };
  toUser: {
    id: string;
    name: string;
    textName: string;
  };
};

export function getVotesByGuidApi(guid: string): Promise<AxiosResponse<VoteAdmin[], any>> {
  return axiosInstance.get(`/votes/${guid}`);
}

export function getUsersByGuidApi(guid: string): Promise<AxiosResponse<UserAdmin[], any>> {
  return axiosInstance.get(`/users/${guid}`);
}

export function getTextsAdminByGuidApi(guid: string): Promise<AxiosResponse<TextAdmin[], any>> {
  return axiosInstance.get(`/textsAdmin/${guid}`);
}
